import React from 'react';
import './App.css';
import p5 from 'p5';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';

import sunImg from './assets/sun.png';
import rocket from './assets/rocket.gif';
import among_us from './assets/among_us.png';

class App extends React.Component {
  private p5!: p5;
	lastScrollTop = 0;
  shift = 0;

  async componentDidMount() {
    this.p5 = new p5(this.sketch.bind(this));
		this.galleryInit();

    window.addEventListener('scroll', this.onScroll.bind(this));

    await fetch('https://uxgo.tv/api/anal', {
      method: 'POST'
    }).then(res => res.json());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll.bind(this));
  }

  private sketch(p: p5) {
		const scale = 40,
			angle = p.PI / 3;
		let sun: p5.Image,
			cols = window.innerWidth / scale,
			rows = window.innerHeight / scale;

		p.setup = () => {
			p.noLoop();
			p.createCanvas(window.innerWidth, window.innerHeight, p.WEBGL);
			sun = p.loadImage(sunImg, sun => {
				p.translate(p.width / 2, 0);
				p.rotateX(-angle);
				p.image(sun, 0, -(p.height / 2));
			});
		};

		p.draw = () => {
			p.clear();
			// p.background(0);
			p.push();
			p.image(sun, 0, -(p.height / 2));
			p.pop();
			p.translate(-p.width / 2, 0);
			p.rotateX(angle);
			p.fill(0);
			// p.strokeWeight(2);
			p.stroke('#7b1fa2');
			for (let y = 0; y < rows; y++) {
				for (let x = 0; x < cols; x++) {
					p.square(x * scale, y * scale + (this.shift * 2), scale);
				}
			}
			if (!((this.shift * 2) % scale)) {
				this.shift = 0
			}
		};

		p.windowResized = () => {
			cols = window.innerWidth / scale;
			rows = window.innerHeight / scale;
			p.resizeCanvas(p.windowWidth, p.windowHeight);
		}
	}

	onScroll(event: Event) {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    (st < this.lastScrollTop) ? this.shift++ : this.shift--;
    this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    this.p5.redraw();
    // const first = document.querySelectorAll('.card')[0] as HTMLElement;
    // first.style.position = 'relative';
    // const firstTop = 395;
    // const math = firstTop - st;
    // // console.log(first.offsetTop, first.getBoundingClientRect().y, math)
    // first.style.top = String((math) + 'px');
    // const screenMiddle = window.innerHeight / 2;
    // const elMiddle = first.getBoundingClientRect().top + (first.getBoundingClientRect().height / 2);
    // const factor = 1 / (Math.abs(screenMiddle - elMiddle) / 100);
    // first.style.opacity = String(factor);
    // // cool effect but not what I'm looking for
    // // first.style.transform = String(`scale(${Math.min(factor, 1)})`);
    // const scaleMath = Math.max(screenMiddle - elMiddle, 0) / 100;
    // first.style.transform = String(`scale(${scaleMath})`);
    // console.log(scaleMath)
	}

	private galleryInit() {
		GLightbox({
			selector: '.t5-gallery',
			touchNavigation: true,
		});
		GLightbox({
			selector: '.sp-gallery',
			touchNavigation: true,
		});
		GLightbox({
			selector: '.hl-gallery',
			touchNavigation: true,
		});
		GLightbox({
			selector: '.sw-gallery',
			touchNavigation: true,
		});
		GLightbox({
			selector: '.nn-gallery',
			touchNavigation: true,
		});
	}

  render() {
    return (
      <>
      <main>
        <img src={rocket} alt="" className="rocket" />
        <img src={among_us} alt="" className="among-us" />
        <div className="wrapper">
          <header>
            {/* <div className="menu">
            <a href="/portfolio">PORTFOLIO</a>
            <a className="activetab" href="/">PROJECTS</a>
            <a href="/notes">NOTES</a>
            </div> */}
            <ul id="social">
              <li>
                <a target="_blank" href="https://www.instagram.com/artdesire/" className="ig">
                  <div className="ig"></div>

                </a>
              </li>
              <li>
                <a target="_blank" href="https://github.com/iamart" className="gh">
                  <div className="gh"></div>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.linkedin.com/in/art-titov/" className="in">
                  <div className="in"></div>
                </a>
              </li>
            </ul>
          </header>
          <img className="logo" src="./assets/logo-neon.png"
            srcSet={"./assets/logo-neon.png 270w, ./assets/logo-neon-hires.png 816w"} style={{width: 270}} alt="iamart" />
          {/* <div className="container-fluid" style="padding: 20% 20%; background: url('iamart.svg') no-repeat; background-size: 500px;"></div> */}
          <div className="message">
            <h1>Full stack developer, primarily working in Typescript/Node.js environment. Graduated as Aircraft Maintenance Engineer to help the industry go electric. 🙌🛫</h1>
          </div>
          <h1>personal projects</h1>
          <div className="card">
            <img src="/assets/projects/uxgo.svg" alt="uxGO" />
            <p className="card-text">A place where you can watch quality content including top notch events around the world
              live
              &amp; on demand.</p>
            <a href="http://uxgo.tv" className="btn">Visit</a>
          </div>
          <div className="card">
            <span className="date">2010 - 2014</span>
            <img src="/assets/projects/top-5ive.net.png" alt="Top-5ive.Net" />
            <p>Popular video game network back in time. <br />
              <span>Counter-Strike: Source <br />
                Counter-Strike: Global Offensive <br />
                Minecraft <br />
                Grand Theft Auto V <br />
                Clash of Clans
              </span>
            </p>
            <div className="gallery">
              <a href="/assets/projects/top-5ive-1.png" className="t5-gallery" data-gallery="t5-gallery">
                <img src="/assets/projects/top-5ive-1.png" alt="Top-5ive.Net" />
              </a>
              <a href="/assets/projects/top-5ive-3.jpg" className="t5-gallery" data-gallery="t5-gallery">
                <img src="/assets/projects/top-5ive-3.jpg" alt="Top-5ive.Net" />
              </a>
              <a href="/assets/projects/top-5ive-4.jpg" className="t5-gallery" data-gallery="t5-gallery">
                <img src="/assets/projects/top-5ive-4.jpg" alt="Top-5ive.Net" />
              </a>
              <a href="/assets/projects/top-5ive-5.jpg" className="t5-gallery" data-gallery="t5-gallery">
                <img src="/assets/projects/top-5ive-5.jpg" alt="Top-5ive.Net" />
              </a>
              <a href="/assets/projects/top-5ive-2.jpg" className="t5-gallery" data-gallery="t5-gallery">
                <img src="/assets/projects/top-5ive-2.jpg" alt="Top-5ive.Net" />
              </a>
              <a href="/assets/projects/top-5ive-6.jpg" className="t5-gallery" data-gallery="t5-gallery">
                <img src="/assets/projects/top-5ive-6.jpg" alt="Top-5ive.Net" />
              </a>
            </div>
            <a href="https://steamcommunity.com/groups/top5ive" className="steam">
              <svg xmlns="http://www.w3.org/2000/svg" height="36" width="36"
                viewBox="465.154 348.168 126.794 121.613">
                <path
                  d="M585.017 365.53h1.08c.703 0 1.136-.449 1.136-1.004 0-.553-.29-.924-1.13-.924h-1.086zm1.13-2.734c1.554 0 2.014.81 2.014 1.646 0 .807-.49 1.334-1.053 1.608l1.365 2.574h-1.034l-1.172-2.299h-1.254v2.3h-.852v-5.83zm-5.056 3.012c0 3.113 2.272 5.07 4.993 5.07 2.717 0 4.976-1.957 4.976-5.07 0-3.124-2.259-5.05-4.976-5.05s-4.993 1.935-4.993 5.05m10.857 0c0 3.646-2.733 5.924-5.864 5.924-3.133 0-5.88-2.278-5.88-5.924 0-3.644 2.747-5.904 5.88-5.904 3.13 0 5.864 2.26 5.864 5.904m-66.199-17.64c-31.94 0-58.109 24.63-60.595 55.929l32.59 13.475a17.107 17.107 0 0110.645-2.966l14.495-21.008-.003-.297c0-12.644 10.287-22.933 22.934-22.933 12.642 0 22.93 10.29 22.93 22.933 0 12.645-10.288 22.933-22.932 22.933-.173 0-.346-.004-.52-.008l-20.67 14.75c.01.269.02.542.02.813 0 9.493-7.72 17.213-17.212 17.213-8.332 0-15.298-5.946-16.876-13.821l-23.307-9.636c7.216 25.524 30.667 44.236 58.501 44.236 33.583 0 60.807-27.227 60.807-60.807 0-33.582-27.224-60.806-60.807-60.806" />
                <path
                  d="M503.259 440.432l-7.47-3.086a12.859 12.859 0 006.655 6.33c6.573 2.74 14.15-.38 16.89-6.956a12.83 12.83 0 00.02-9.884 12.84 12.84 0 00-6.975-7.007 12.873 12.873 0 00-9.52-.144l7.716 3.19a9.51 9.51 0 01-7.316 17.557m31.102-47.158a11.477 11.477 0 0111.479-11.477 11.477 11.477 0 0111.479 11.477 11.477 11.477 0 01-11.48 11.478 11.477 11.477 0 01-11.479-11.478m26.733.027c0-8.425-6.853-15.28-15.28-15.28-8.424 0-15.28 6.855-15.28 15.28 0 8.427 6.856 15.28 15.28 15.28 8.427 0 15.28-6.853 15.28-15.28" />
              </svg>
            </a>
            <a href="http://web.archive.org/web/20130903182730/http://top-5ive.net/" className="btn">Cached</a>
          </div>
          <div className="card">
            <span className="date">2014 - 2015</span>
            <img src="/assets/projects/tastatic.png" alt="Tastatic" style={{filter: 'invert(1)'}} />
            <p className="card-text">Restaurant database search.</p>
          </div>
          <div className="card">
            <span className="date">2009 - 2010</span>
            <img src="/assets/projects/HL.png" alt="HACKLine" style={{maxHeight: 200}} />
            <p>web browser based on Trident engine.</p>
            <div className="gallery">
              <a href="/assets/projects/hl-screenshot.png" className="hl-gallery" data-gallery="hl-gallery">
                <img src="/assets/projects/hl-screenshot.png" alt="HACKLine" />
              </a>
              <a href="/assets/projects/hl-website.jpg" className="hl-gallery" data-gallery="hl-gallery">
                <img src="/assets/projects/hl-website.jpg" alt="HACKLine" />
              </a>
            </div>
            <a href="https://web.archive.org/web/20090905170342/http://nickov.net.ru/hackline/" className="btn">Cached</a>
          </div>
          <div className="card">
            <span className="date">2013</span>
            <h1>Better Build System</h1>
            <p>improvements to Sublime Text 2 build system.</p>
            <a href="https://github.com/iamart/Better-Build-System" className="btn">GitHub</a>
          </div>
          <div className="card">
            <span className="date">2012</span>
            <p>SourcePawn syntax highlighting for Notepad++</p>
            <a href="/assets/projects/nppsyntax.png" className="sp-gallery" data-gallery="sp-gallery">
              <img src="/assets/projects/nppsyntax.png" alt="SourcePawn syntax highlighting" className="gallery-item" />
            </a>
            <a href="https://forums.alliedmods.net/showthread.php?t=201050" className="btn">AlliedModders</a>
          </div>
          <div className="card">
            <span className="date">2017</span>
            <p>Flight schedule for Swissport ramp employees.</p>
            <a href="/assets/projects/rampyyc.jpg" className="sw-gallery" data-gallery="sw-gallery">
              <img src="/assets/projects/rampyyc.jpg" alt="Swissport" className="gallery-item" />
            </a>
          </div>
          <div className="card">
            <span className="date">2008 - 2010</span>
            <img src="/assets/projects/nickov-net.png" alt="NICKov Net!" />
            <p>Software, scripts, and tools sharing portal for tech-maniacs.</p>
            <a href="/assets/projects/nickov.net.jpg" className="sw-gallery" data-gallery="nn-gallery">
              <img src="/assets/projects/nickov.net.jpg" alt="NICKov Net!" className="gallery-item" />
            </a>
            <a href="https://web.archive.org/web/20100926100415/http://nickov.net.ru:80/" className="btn">Cached</a>
          </div>
        </div>
      </main>
      {/* <footer>
        <span className="text-muted">#YYC | Art Titov
          <br /><!-- 2016- -->©2023</span>
      </footer> */}
    </>
    )
  };
}

export default App;
